import React from 'react'
import Sidebarmenu from '../components/Sidebarmenu'
export default function Profile(props) {
  const apidata = props.apidata;
  return (
    <div>

    <div className="navbar-dark bg-dark" style={{   backgroundImage: `url("assets/svg/components/wave-pattern-light.svg")` }} >
          <div className="container content-space-1 content-space-b-lg-3">
            <div className="row align-items-center">
              <div className="col">
                <div className="d-none d-lg-block">
                  <h1 className="h2 text-white">Personal Info</h1>
                </div>
    
            
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light mb-0">
                    <li className="breadcrumb-item">Manage Your Profile</li>
                   
                  </ol>
                </nav>
               
              </div>
           
    
              <div className="col-auto">
                <div className="d-none d-lg-block">
                  
                </div>
    
             
                <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <div className="navbar-toggler-default">
                    <i className="bi-list"></i>
                  </div>
                  <div className="navbar-toggler-toggled">
                    <i className="bi-x"></i>
                  </div>
                </button>
               
              </div>
            
            </div>
           
          </div>
        </div>
        <div className="container  content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className='row'>
    
    
            <div className="col-lg-3">
            <Sidebarmenu apidata={apidata}/>
            </div>  


        <div className="col-lg-9">
          <div className="d-grid gap-3 gap-lg-5">
            <div className="card">
              <div className="card-header border-bottom">
                <h4 className="card-header-title">Personal Budgeting Service</h4>
              </div>             
              <div className="card-body">

                <dl className="row">
                  <dt className="col-6">Your Client ID:</dt>
                  <dd className="col-6">{apidata.Client.id}</dd>

                  <dt className="col-6">Product:</dt>
                  <dd className="col-6">{apidata.Client.product}</dd>

                 

                  <dt className="col-6">We Sent Information Pack on:</dt>
                  <dd className="col-6">{apidata.Client.keInfopckSnt}
</dd>

                  <dt className="col-6">Received Signed Documents on:</dt>
                  <dd className="col-6">{apidata.Client.keAuthrty}
</dd>

                  <dt className="col-6">Your next payment due on:</dt>
                  <dd className="col-6">{apidata.Client.nxtPmntInDte}</dd>

                  <dt className="col-6">Status:</dt>
                  <dd className="col-6"><span className="badge bg-soft-info text-info" dangerouslySetInnerHTML={{__html: apidata.Client.status}}></span></dd>
                </dl>





              </div>

            </div> 
          </div>

          <div className="d-grid gap-3 gap-lg-5 mt-4" >
            <div className="card">
              <div className="card-header border-bottom">
                <h4 className="card-header-title">Applicant 1</h4>
              </div>             
              <div className="card-body">

                <dl className="row">
                  <dt className="col-6">Name:</dt>
                  <dd className="col-6">{apidata.Client.App1}</dd>

                  <dt className="col-6">Date of Birth:</dt>
                  <dd className="col-6">{apidata.Client.dteOfBrth1}</dd>

                  <dt className="col-6">Phone Numbers:</dt>
                  <dd className="col-6">{apidata.Client.contacts1}</dd>

                  <dt className="col-6">Email Address:</dt>
                  <dd className="col-6">{apidata.Client.emlAddrss1}</dd>

                  <dt className="col-6">Employment:</dt>
                  <dd className="col-6">{apidata.Client.employment_1}</dd>

                  <dt className="col-6">Occupation:</dt>
                  <dd className="col-6">{apidata.Client.occupation_1}</dd>
         
                </dl>





              </div>

            </div> 
          </div>
        {apidata.Client.App2?<>
          <div className="d-grid gap-3 gap-lg-5 mt-4" >
            <div className="card">
              <div className="card-header border-bottom">
                <h4 className="card-header-title">Applicant 2</h4>
              </div>             
              <div className="card-body">

                <dl className="row">
                  <dt className="col-6">Name:</dt>
                  <dd className="col-6">{apidata.Client.App2}</dd>

                  <dt className="col-6">Date of Birth:</dt>
                  <dd className="col-6">{apidata.Client.dteOfBrth2}</dd>

                  <dt className="col-6">Phone Numbers:</dt>
                  <dd className="col-6">{apidata.Client.contacts2}</dd>

                  <dt className="col-6">Email Address:</dt>
                  <dd className="col-6">{apidata.Client.emlAddrss2}</dd>

                  <dt className="col-6">Employment:</dt>
                  <dd className="col-6">{apidata.Client.employment_2}</dd>

                  <dt className="col-6">Occupation:</dt>
                  <dd className="col-6">{apidata.Client.occupation_2}</dd>
         
                </dl>





              </div>

            </div> 
          </div>
          </>:<></> }

          <div className="d-grid gap-3 gap-lg-5 mt-4" >
            <div className="card">
              <div className="card-header border-bottom">
                <h4 className="card-header-title">Current Address</h4>
              </div>             
              <div className="card-body">

                <dl className="row">
                  <dt className="col-6">Current Address</dt>
                  <dd className="col-6">{apidata.Client.Current_Address}</dd>

                </dl>





              </div>

            </div> 
          </div>



        </div>         
      </div>

    </div>     

     
  </div>
  )
}
