import React, {useState, useEffect} from 'react'
import axios from 'axios';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Sidebarmenu from '../components/Sidebarmenu'

export default function NewsLetterslist(props) {
  const apidata = props.apidata;


  const [letters, setletters]= useState('');
  const [loading, setloading]= useState(true);

  useEffect(() => {
    axios.get(`https://clearapp.clientmaster.uk/api/newsletters.php`).then((getdata) => {  
       
    console.log(getdata.data.newsletters);
    setletters(getdata.data.newsletters);          
    setloading(false);
    })
  },[])

  return (
    <div>

    <div className="navbar-dark bg-dark" style={{   backgroundImage: `url("assets/svg/components/wave-pattern-light.svg")` }} >
          <div className="container content-space-1 content-space-b-lg-3">
            <div className="row align-items-center">
              <div className="col">
                <div className="d-none d-lg-block">
                  <h1 className="h2 text-white">Newsletters </h1>
                </div>
    
            
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light mb-0">
                    <li className="breadcrumb-item">Monthly Newsletters Archive</li>
                   
                  </ol>
                </nav>
               
              </div>
           
    
              <div className="col-auto">
                <div className="d-none d-lg-block">
                
                </div>
    
             
                <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <div className="navbar-toggler-default">
                    <i className="bi-list"></i>
                  </div>
                  <div className="navbar-toggler-toggled">
                    <i className="bi-x"></i>
                  </div>
                </button>
               
              </div>
            
            </div>
           
          </div>
        </div>
        <div className="container  content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className='row'>
    
    
            <div className="col-lg-3">
            <Sidebarmenu apidata={apidata}/>
            </div>  

        <div className="col-lg-9">
          <div className="d-grid gap-3 gap-lg-5">
          <div className="card">
              <div className="card-header border-bottom">
                <h4 className="card-header-title">Monthly Newsletters</h4>
              </div>             
              <div className="card-body">
               {loading? <><Skeleton count={6} /></>: 
              <ul className="list-group list-group-lg">
             
               
              {letters.map(data => {                        
                      return (
                  
                <li key={data.id} className="list-group-item">
                <div className="row">
                    <div className="col-md-4 mb-2 mb-md-0">
                    <span className="h6">{data.title}</span>
                    </div>
                        

                    <div className="col-sm-5 col-md-4 mb-2 mb-sm-0">
                    <span> {data.date} </span>
                    </div>
                
                  
                    <div className="col-sm-2 text-sm-end">
                    {data.file?<><a href={"https://clearapp.clientmaster.uk/uploads/"+data.file} target={'blank'}>View  <i className="bi-chevron-right small ms-1 small ms-1"></i></a></>:<></>}
                    </div>
                
                </div>
                
                </li>

              )
              })} 

                
            </ul>
        }



              </div>

            </div> 
       
          </div>
        </div> 
      </div>

    </div>     

     
  </div>
  )
}
