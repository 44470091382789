import React from 'react'
import Sidebarmenu from '../components/Sidebarmenu'
export default function SMSlist(props) {
  const apidata = props.apidata;
  const smslist = apidata.Correspondence.sms.list;


  return (
    <div>

    <div className="navbar-dark bg-dark" style={{   backgroundImage: `url("assets/svg/components/wave-pattern-light.svg")` }} >
          <div className="container content-space-1 content-space-b-lg-3">
            <div className="row align-items-center">
              <div className="col">
                <div className="d-none d-lg-block">
                  <h1 className="h2 text-white">Messaging</h1>
                </div>
    
            
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light mb-0">
                    <li className="breadcrumb-item">Your SMS Chat History</li>
                   
                  </ol>
                </nav>
               
              </div>
           
    
              <div className="col-auto">
                <div className="d-none d-lg-block">
                 
                </div>
    
             
                <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <div className="navbar-toggler-default">
                    <i className="bi-list"></i>
                  </div>
                  <div className="navbar-toggler-toggled">
                    <i className="bi-x"></i>
                  </div>
                </button>
               
              </div>
            
            </div>
           
          </div>
        </div>
        <div className="container  content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className='row'>
    
    
            <div className="col-lg-3">
            <Sidebarmenu apidata={apidata}/>
            </div>  


        <div className="col-lg-9">
          <div className="d-grid gap-3 gap-lg-5">
          <div className="card">
              <div className="card-header border-bottom">
                <h4 className="card-header-title">SMS Chat History</h4>
              </div>             
              <div className="card-body">

              <ul className="list-comment">
                  
              {smslist.map(data => {                        
                      return (
                  
                <li key={data.id}  className="list-comment-item">
                   
                    <div className="d-flex align-items-center mb-3">
                      <div className="flex-shrink-0">
                        <img className="avatar avatar-circle" src="icon.png" alt="Image Description"/>
                      </div>

                      <div className="flex-grow-1 ms-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <h6>{data.number}</h6>
                          <span className="d-block small text-muted">{data.datetime}</span>
                        </div>
                      </div>
                    </div>
                   

                    <p>{data.message}</p>

                  </li>
             )
            })} 

                
            
                </ul>




              </div>

            </div> 
        
          </div>
        </div> 
      </div>

    </div>     

     
  </div>
  )
}
