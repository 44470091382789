import React, { useState } from 'react'
import Sidebarmenu from '../components/Sidebarmenu'

export default function Letterslist(props) {
  const apidata = props.apidata;
  const letters = apidata.Correspondence.letter.list;


  return (
    <div>

    <div className="navbar-dark bg-dark" style={{   backgroundImage: `url("assets/svg/components/wave-pattern-light.svg")` }} >
          <div className="container content-space-1 content-space-b-lg-3">
            <div className="row align-items-center">
              <div className="col">
                <div className="d-none d-lg-block">
                  <h1 className="h2 text-white">Letters </h1>
                </div>
    
            
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light mb-0">
                    <li className="breadcrumb-item">Letters Communication</li>
                   
                  </ol>
                </nav>
               
              </div>
           
    
              <div className="col-auto">
                <div className="d-none d-lg-block">
                
                </div>
    
             
                <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <div className="navbar-toggler-default">
                    <i className="bi-list"></i>
                  </div>
                  <div className="navbar-toggler-toggled">
                    <i className="bi-x"></i>
                  </div>
                </button>
               
              </div>
            
            </div>
           
          </div>
        </div>
        <div className="container  content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className='row'>
    
    
            <div className="col-lg-3">
            <Sidebarmenu apidata={apidata}/>
            </div>  

        <div className="col-lg-9">
          <div className="d-grid gap-3 gap-lg-5">
          <div className="card">
              <div className="card-header border-bottom">
                <h4 className="card-header-title">Letters Communication</h4>
              </div>             
              <div className="card-body">

              <ul className="list-group list-group-lg">
              {letters.map(data => {                        
                      return (
                  
                <li key={data.id} className="list-group-item">
                <div className="row">
                    <div className="col-md-4 mb-2 mb-md-0">
                    <span className="h6">{data.dscrptn}</span>
                    </div>
                        

                    <div className="col-sm-5 col-md-4 mb-2 mb-sm-0">
                    <span> {data.updtdwhn} </span>
                    </div>
                
                  
                    <div className="col-sm-2 text-sm-end">
                    {data.file?<><a href={"https://docs.google.com/gview?url=https://clientmaster-uk-clearstartaccountants.s3.eu-west-1.amazonaws.com/"+data.file.location+data.file.name} target={'blank'}>View  <i className="bi-chevron-right small ms-1 small ms-1"></i></a></>:<></>}
                    </div>
                
                </div>
                
                </li>

              )
              })} 
               
                

                
            </ul>




              </div>

            </div> 
       
          </div>
        </div> 
      </div>

    </div>     

     
  </div>
  )
}
