import React, { useState } from 'react'
import Sidebarmenu from '../components/Sidebarmenu'
export default function Creditorinfo(props) {
  const apidata = props.apidata;
  const creditordata = apidata?.Creditor?.information;


  return (
    <div>

    <div className="navbar-dark bg-dark" style={{   backgroundImage: `url("assets/svg/components/wave-pattern-light.svg")` }} >
          <div className="container content-space-1 content-space-b-lg-3">
            <div className="row align-items-center">
              <div className="col">
                <div className="d-none d-lg-block">
                  <h1 className="h2 text-white">Liability Information</h1>
                </div>
    
            
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light mb-0">
                    <li className="breadcrumb-item">Manage Liability Information</li>
                   
                  </ol>
                </nav>
               
              </div>
           
    
              <div className="col-auto">
                <div className="d-none d-lg-block">
                 
                </div>
    
             
                <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <div className="navbar-toggler-default">
                    <i className="bi-list"></i>
                  </div>
                  <div className="navbar-toggler-toggled">
                    <i className="bi-x"></i>
                  </div>
                </button>
               
              </div>
            
            </div>
           
          </div>
        </div>
        <div className="container  content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className='row'>
    
    
            <div className="col-lg-3">
            <Sidebarmenu apidata={apidata}/>
            </div>  

        <div className="col-lg-9">
          <div className="d-grid gap-3 gap-lg-5">
          <div className="card">
              <div className="card-header border-bottom">
                <h4 className="card-header-title">Liability Information</h4>
              </div>             
              <div className="card-body">

              <ul className="list-unstyled mb-5">
                  
              
              {creditordata?.map(data => {                        
                                    return (
                                     
                    <li  key={data.id} className="card card-bordered shadow-5 mb-5 " style={{borderColor:'#ccc'}}>
                      <div className="card-body">
                        
                       

                   

                        <div className="row">
                          <div className="col-md-8">                        

                            <div className="row ">
                              <div className="col-md-6">
                              <div className="form-check-label" for="billingRadio1">
                                <div className="h5 d-block">{data.nme}</div>

                                <div className="d-block mb-2">
                                {data.nme}<br/>
                                Repayment : <span dangerouslySetInnerHTML={{__html: data.rePmnt}}></span>
                                                    
                              
                                </div>                              
                              </div>
                              </div>
                          
                            
                              <div className="col-md-6">
                              <div className="form-check-label" for="billingRadio1">
                                <div className="h5 d-block">Account Info</div>

                                <div className="d-block mb-2">
                                {data.accntNme}<br/>
                                Account Number: {data.accntNmbr}<br/>
                                Sort code: {data.srtcde}<br/>
                               
                                </div>

                              
                              </div>
                              </div>
                              </div>

                            
                           
                          </div>

                          <hr/>

                          <div className="row">
                          <div className="col-6 col-md mb-3 mb-md-0">
                            <small className="card-subtitle mb-0">Balance</small>
                            <small className="text-dark fw-semi-bold" dangerouslySetInnerHTML={{__html: data.Balance}}></small>
                          </div>
                        

                          <div className="col-6 col-md mb-3 mb-md-0">
                            <small className="card-subtitle mb-0">Credit Received</small>
                            <small className="text-dark fw-semi-bold" dangerouslySetInnerHTML={{__html: data.crdtAgrmntRcvd}}></small>
                          </div>
                        

                          <div className="col-6 col-md">
                            <small className="card-subtitle mb-0">Responsibly</small>
                            <small className="text-dark fw-semi-bold">{data.rspnsblty}</small>
                          </div>
                        

                          <div className="col-6 col-md">
                            <small className="card-subtitle mb-0">Status</small>
                            <small className="text-dark fw-semi-bold" dangerouslySetInnerHTML={{__html: data.status}}></small>
                          </div>
                        
                        </div>
                          
                        </div>
                      </div>                      
                    </li>             

                                    
                
              )
              })}  
                    
                   
                   
                   
                  </ul>




              </div>

            </div> 
          </div>

          </div>
        </div> 
      </div>

    </div>     

     

  )
}
