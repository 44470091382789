import React, {useState, useEffect} from 'react'
import axios from 'axios';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate, Link  } from 'react-router-dom';

import Sidebarmenu from '../components/Sidebarmenu'

export default function Dashboard(props) {
  const apidata = props.apidata;
  const [offers, setoffers]= useState('');
  const [loading, setloading]= useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`https://clearapp.clientmaster.uk/api/weboffers.php`).then((getdata) => {  
       
    console.log(getdata.data.offers);
    setoffers(getdata.data.offers);          
    setloading(false);

 
      })

      const loginfo = JSON.parse(localStorage.getItem('login'));
      const config = {
        headers: { Authorization: `Bearer ${loginfo.token}` }
      };
    
      axios.get(`https://clearstart-api.clientmaster.co.uk/api/portal/client/show-all`,config).then((response) => {     
       // console.log(response);
        let userinfo = JSON.stringify(response.data);
        localStorage.setItem('data', userinfo);    
    
        console.log('database updated')
          }).catch((error) => {  
            console.log('login timeout');
            localStorage.clear();      
            navigate('/login')  
          });


  },[])


  
  
  return (
    <div>
        <div className="navbar-dark bg-dark" style={{   backgroundImage: `url("assets/svg/components/wave-pattern-light.svg")` }} >
      <div className="container content-space-1 content-space-b-lg-3">
        <div className="row align-items-center">
          <div className="col">
            <div className="d-none d-lg-block">
              <h1 className="h2 text-white">Welcome Back!</h1>
            </div>

        
            <nav aria-label="breadcrumb">
             
            </nav>
           
          </div>
       

          <div className="col-auto">
            <div className="d-none d-lg-block">
              
            </div>

         
            <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false" aria-label="Toggle navigation">
              <div className="navbar-toggler-default">
                <i className="bi-list"></i>
              </div>
              <div className="navbar-toggler-toggled">
                <i className="bi-x"></i>
              </div>
            </button>
           
          </div>
        
        </div>
       
      </div>
    </div>
    
      <div className="container  content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
    
        <div className='row'>


          <div className="col-lg-3">
            <Sidebarmenu apidata={apidata}/>
            
          </div>  


          <div className="col-lg-9">
            <div className="d-grid gap-3 gap-lg-5">
               
            <div className="card" style={{border:'solid 2px #ccc'}}>
            

               
 <div className="container gap-lg-5  mt-5">
  <div className="w-lg-100 mx-lg-auto">
    <div className="card card-sm overflow-hidden">
    {loading?<Skeleton count={3} />
    :
      <div className="card-body">
        <div className="row justify-content-md-start align-items-md-center text-center text-md-start">
          <div className="col-md offset-md-3 mb-3 mb-md-0">
            <h4 className="card-title">{offers[4].ofname}</h4>
          </div>

          <div className="col-md-auto">
            <a className="btn btn-primary btn-transition" href={offers[4].link} target='_blank'>{offers[4].ofcontent}</a>
          </div>
        </div>

     
        <div className="w-25 d-none d-md-block position-absolute top-0 start-0 mt-n2">
          <img className="card-img" src="../assets/svg/illustrations/apps.svg" alt="Image Description"/>
        </div>
   
      </div>
}
    </div>
  </div>
</div>
               





        <div className="container mt-5">
          
       

          <div className="row">
            <div className="col-sm-6 col-lg-6 mb-6 mb-lg-0">
            {loading?<Skeleton count={5} />
           :
              <a className="card card-transition bg-light h-100 shadow-none overflow-hidden" href={offers[3].link}>
                <div className="row align-items-center">
                  <div className="col-8 col-md-6">
                    <div className="card-body">
                      <h4 className="card-title">{offers[3].ofname}</h4>
                      <span className="card-link">{offers[3].ofcontent} <i className="bi-chevron-right small"></i></span>
                    </div>
                  </div>
            

                  <div className="col-4 col-md-6 bg-img-end h-100 px-0" style={{backgroundImage: `url(https://clearapp.clientmaster.uk/uploads/`+offers[3].oficon+`)`}}  ></div>
            
                </div>
                
              </a>
}
         
            </div>
      

            <div className="col-sm-6 col-lg-6 mb-6 mb-lg-0">
            {loading?<Skeleton count={5} />
           :
              <a className="card card-transition bg-light h-100 shadow-none overflow-hidden" href={offers[2].link}>
                <div className="row align-items-center">
                  <div className="col-8 col-md-6">
                    <div className="card-body">
                      <h4 className="card-title">{offers[2].ofname}</h4>
                      <span className="card-link">{offers[2].ofcontent} <i className="bi-chevron-right small"></i></span>
                    </div>
                  </div>
            
                  
                  <div className="col-4 col-md-6 bg-img-end h-100 px-0" style={{backgroundImage: `url(https://clearapp.clientmaster.uk/uploads/`+offers[2].oficon+`)`}}></div>
            
                </div>
                
              </a>
            }
            </div>
      

            
 
          </div>
       
        </div>






            <div className="container mt-5  mb-5">
              <div className="row">
                <div className="col-lg-6 mb-3 mb-lg-0">
                {loading?<Skeleton count={7} />
           :
                  <div className="card bg-primary h-100 overflow-hidden">
                    <div className="card-body">
                      <div className="w-65 pe-2">
                        <h2 className="card-title text-white">{offers[1].ofname}</h2>
                        <p className="card-text text-white">{offers[1].ofcontent}</p>
                        <a className="btn btn-light btn-sm btn-transition" href={offers[1].link}>Learn more <i className="bi-chevron-right small ms-1"></i></a>
                      </div>

                      <div className="position-absolute end-0 bottom-0 w-50 mb-n3 me-n7">
                        <img className="card-img" src={"https://clearapp.clientmaster.uk/uploads/"+offers[1].oficon} alt="Image Description"/>
                      </div>
                    </div>
                  </div>
          }
                </div>
              

                <div className="col-lg-6">

                
                {loading?<Skeleton count={7} />
           :
                  <div className="card bg-warning h-100 overflow-hidden">
                    <div className="card-body">
                      <div className="w-65 pe-2">
                        <h2 className="card-title text-white">{offers[0].ofname}</h2>
                        <p className="card-text text-white">{offers[0].ofcontent}</p>
                        <a className="btn btn-light btn-sm btn-transition" href={offers[0].link}>Learn more <i className="bi-chevron-right small ms-1"></i></a>
                      </div>

                      <div className="position-absolute end-0 bottom-0 w-50 mb-n3 me-n7">
                        <img className="card-img" src={"https://clearapp.clientmaster.uk/uploads/"+offers[0].oficon} alt="Image Description"/>
                      </div>
                    </div>
                  </div>
                }
                </div>
              
              </div>
             
            
            </div>
            </div>

            
          </div> 
        </div>
        </div> 
  

      </div>     
    
       
    </div>
  )
}
