import React, { useState } from 'react'
import Sidebarmenu from '../components/Sidebarmenu'

export default function Invoices(props) {
  const apidata = props.apidata;
  const invoicelist = apidata.Invoice.list;

  const [invoicedata, setinvoicedata]= useState(null);

console.log(invoicelist);

const Print = () =>{     
  console.log('print');  
  let printContents = document.getElementById('invoices').innerHTML;
  let originalContents = document.body.innerHTML;
  document.body.innerHTML = printContents;
  window.print();
 document.body.innerHTML = originalContents;
 
}





  return (
    <div>

    <div className="navbar-dark bg-dark" style={{   backgroundImage: `url("assets/svg/components/wave-pattern-light.svg")` }} >
          <div className="container content-space-1 content-space-b-lg-3">
            <div className="row align-items-center">
              <div className="col">
                <div className="d-none d-lg-block">
                  <h1 className="h2 text-white">Invoices</h1>
                </div>
    
            
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light mb-0">
                    <li className="breadcrumb-item">Manage Your Invoices</li>
                   
                  </ol>
                </nav>
               
              </div>
           
    
              <div className="col-auto">
                <div className="d-none d-lg-block">
                 
                </div>
    
             
                <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <div className="navbar-toggler-default">
                    <i className="bi-list"></i>
                  </div>
                  <div className="navbar-toggler-toggled">
                    <i className="bi-x"></i>
                  </div>
                </button>
               
              </div>
            
            </div>
           
          </div>
        </div>
        <div className="container  content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className='row'>
    
    
            <div className="col-lg-3">
            <Sidebarmenu apidata={apidata}/>
            </div> 


        <div className="col-lg-9">
          <div className="d-grid gap-3 gap-lg-5">
          <div className="card">
              <div className="card-header border-bottom">
              {invoicedata?<>
                <button type="button" style={{float:'right',}} className="btn btn-primary btn-sm"  onClick={Print} >
              Print Invoice
              </button>
              <button type="button" style={{float:'right', marginRight:'15px'}} onClick={(e)=> setinvoicedata(null)} className="btn btn-warning btn-sm" >
               Back
              </button>
              </>:<></> }
              
                <h4 className="card-header-title">Your Invoices</h4>
              </div>             
             
            {invoicedata?<>

          

            <div className="card  mb-5 " id='invoices' >
          <div className="card-body" >
            <div className="row justify-content-lg-between">
              <div className="col-sm order-2 order-sm-1 mb-3">
                <div className="mb-2">
                <img  src="../assets/svg/logos/invoice.png" style={{width:'180px'}} alt="Logo"/>
                
                </div>

               
              </div>
          

              <div className="col-sm-auto order-1 order-sm-2 text-sm-end mb-3">
                <div className="mb-3">
                  <h2>Invoice #</h2>
                  <span className="d-block">{invoicedata.id}</span>
                </div>

                <address className="text-dark">
                Universal Square<br/>
                Devonshire Street North<br/>
                Manchester, M12 6JH<br/>
                  United Kingdom<br/><br/>
              VAT : 339502107
                </address>
              </div>
    
            </div>


            <div className="row justify-content-md-between mb-3">
              <div className="col-md">
                <h4>Bill to:</h4>
                <h4>{apidata.Client.App1}</h4>

                <address style={{maxWidth:'45%'}}>
                {apidata.Client.Current_Address}
                 
                </address>
              </div>
            

              <div className="col-md text-md-end">
                <dl className="row">
                  <dt className="col-sm-8">Invoice date:</dt>
                  <dd className="col-sm-4"><small>{invoicedata.date_taken}</small></dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-8">Status</dt>
                  <dd className="col-sm-4"><span className="badge bg-soft-warning text-success">{invoicedata.status}</span></dd>
                </dl>
               
              </div>
      
            </div>
     

          
            <div className="table-responsive">
              <table className="table table-borderless table-nowrap table-align-middle">
                <thead className="thead-light">
                  <tr>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th>Rate</th>
                    <th>VAT</th>
                    <th className="table-text-end">Amount</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <th>{invoicedata.item}</th>
                    <td>1</td>
                    <td>{invoicedata.amount}</td>
                    <td>{invoicedata.vat}</td>
                    <td  className="table-text-end">{invoicedata.total}</td>
                            
                  </tr>

               
                </tbody>
              </table>
            </div>
   

            <hr className="my-5"/>

            <div className="row justify-content-md-end mb-3">
              <div className="col-md-8 col-lg-7">
                <dl className="row text-sm-end">
                  <dt className="col-sm-6">Refund:</dt>
                  <dd className="col-sm-6">{invoicedata.refund_amount}</dd>                
                  <dt className="col-sm-6">VAT:</dt>
                  <dd className="col-sm-6">{invoicedata.vat}</dd>
                  <dt className="col-sm-6">Total:</dt>
                  <dd className="col-sm-6">{invoicedata.total}</dd>

            
                </dl>
      
              </div>
            </div>
   

            <div className="mb-3 mt-5 ">
            <p className='text-center mb-3'><b>Payment Reference ID :</b> {invoicedata.payment_ref}</p>
              <p className='text-center'>If you have any questions concerning this invoice, use the following contact information: cs@clearstartaccountants.co.uk</p>
            </div>

            <p className="small mb-0 text-center">© 2022 clearstartaccountants.co.uk</p>
          </div>
        </div>

            </>:<>

                    
              <div className="table-responsive">
                <table className="table table-borderless table-thead-bordered table-nowrap table-align-middle">
                  <thead className="thead-light">
                    <tr>
                      <th>Reference</th>
                      <th>Status</th>
                      <th>Amount</th>
                      <th>Vat</th>
                      <th>Total</th>
                      <th>Updated</th>
                      <th>View</th>
                     
                     
                    </tr>
                  </thead>

                  <tbody>
                  {invoicelist.map(data => {                        
                                    return (
                                      <tr  key={data.id}>
                      <td><a href="#"  onClick={(e)=> setinvoicedata(data)} >#{data.id}</a></td>
                      <td><span className="badge bg-soft-warning text-success">{data.status}</span></td>
                      <td>	£{data.amount}</td>
                      <td>	£{data.vat}</td>
                      <td>	£{data.total}</td>
                      <td>{data.date_taken}</td>                    
                      <td><a href='#' onClick={(e)=> setinvoicedata(data)} className="badge bg-soft-success text-success">View</a></td>       
                    </tr>


                  )
                  })} 
                  
                  </tbody>
                </table>
              </div>
             
              </>}




              </div>

            
          </div>
        </div> 
      </div>

    </div>     

     
  </div>
  )
}
