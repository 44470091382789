import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { useNavigate, Link  } from 'react-router-dom';


export default function Sidebarmenu(props) {  
  const apidata = props.apidata;

  window.scrollTo(0, 0);


  const navigate = useNavigate();

  const logout = () =>{     
    console.log('logout');  
      localStorage.clear();      
      navigate('/login')  
  }

  return (
    <div>
       
         
         <div className="navbar-expand-lg navbar-light">
           <div id="sidebarNav" className="collapse navbar-collapse navbar-vertical">
           
             <div className="card flex-grow-1 mb-5" style={{border:'solid 2px #ccc'}}>
               <div className="card-body">
                {apidata? 
                 <div className="d-none d-lg-block text-center mb-5">
                   <div className="avatar avatar-xxl avatar-circle mb-3">
                     <img className="avatar-img" src={"https://ui-avatars.com/api/?name="+apidata.Client.App1+"&color=7F9CF5&background=EBF4FF"} alt="Image Description"/>
                     <img className="avatar-status avatar-lg-status" src="assets/svg/illustrations/top-vendor.svg" alt="Image Description" data-bs-toggle="tooltip" data-bs-placement="top" title="Verified user"/>
                   </div>

                   <h4 className="card-title mb-0">{apidata.Client.App1}</h4>
                   <p className="card-text small">{apidata.Client.emlAddrss1}</p>
                 </div>
                 :<></>}

         
               

               
                 <ul className="nav nav-sm nav-tabs nav-vertical mb-4">
                 <li className="nav-item">
                     <Link to="/dashboard" className="nav-link " >
                       <i className="bi-house nav-icon"></i> Dashboard
                     </Link>
                   </li>
                   <li className="nav-item">
                     <Link to="/profile" className="nav-link " >
                       <i className="bi-person-circle nav-icon"></i> Personal info
                     </Link>
                   </li>
                   <li className="nav-item">
                     <Link to="/changepassword" className="nav-link " >
                       <i className="bi-lock nav-icon"></i> Change Password
                     </Link>
                   </li>
                 
                  
                 </ul>


                 <div className="text-cap">Financial</div>

               
                <ul className="nav nav-sm nav-tabs nav-vertical mb-4">
                  <li className="nav-item">
                    <Link className="nav-link " to="/creditors-info">
                      <i className="bi-cash-coin nav-icon"></i> Liability Information
                    </Link>
                  </li>
                 
                 
                </ul>
               

                 <div className="text-cap">Accountancy</div>

              
                 <ul className="nav nav-sm nav-tabs nav-vertical mb-4">
                   <li className="nav-item">
                     <Link className="nav-link " to="/personal-account">
                       <i className="bi-wallet2 nav-icon"></i> Personal Accountancy
                     </Link>
                   </li>
                   <li className="nav-item">
                     <Link className="nav-link " to="/business-account">
                       <i className="bi-briefcase nav-icon"></i> Business Accountancy
                     
                     </Link>
                   </li>
                 </ul>


                 <div className="text-cap">Budgeting</div>
              
                  <ul className="nav nav-sm nav-tabs nav-vertical mb-4">
                    <li className="nav-item">
                      <Link className="nav-link " to="/budgetlist">
                        <i className="bi-basket nav-icon"></i> Budgeting List
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link " to="/income-expense">
                        <i className="bi-currency-exchange nav-icon"></i> Income and Expenditure
                      
                      </Link>
                    </li>
                  </ul>
            

                 <div className="text-cap">Correspondence</div>

      
                 <ul className="nav nav-sm nav-tabs nav-vertical">
                   <li className="nav-item">
                     <Link className="nav-link " to="/letters">
                       <i className="bi-envelope-open nav-icon"></i> Letters
  
                     </Link>
                   </li>
                   <li className="nav-item">
                     <Link className="nav-link " to="/smsdetails">
                       <i className="bi-phone nav-icon"></i> SMS
                      
                     </Link>
                   </li>
                   <li className="nav-item">
                     <Link className="nav-link " to="/emails">
                       <i className="bi-envelope nav-icon"></i> Emails
   
                     </Link>
                   </li>
                   <li className="nav-item">
                     <Link className="nav-link " to="/uploaddocs">
                       <i className="bi-cloud-arrow-up nav-icon"></i> Upload Documents
                     
                     </Link>
                   </li>
                   <li className="nav-item">
                     <Link className="nav-link " to="/invoices">
                       <i className="bi-receipt nav-icon"></i> Invoices
                    
                     </Link>
                   </li>
                   <li className="nav-item">
                     <Link className="nav-link " to="/newsletters">
                       <i className="bi-list nav-icon"></i> Newsletters
                    
                     </Link>
                   </li>

                  
                 </ul>

                 
     

                 <div className="d-lg-none">
                   <div className="dropdown-divider"></div>

             
                   <ul className="nav nav-sm nav-tabs nav-vertical">
                    
                   </ul>
            
                 </div>
      
               </div>
             </div>
  
           </div>
       
   
       </div>
    </div>
  )
}
