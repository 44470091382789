import React from 'react'
import Sidebarmenu from '../components/Sidebarmenu'
export default function Personalaccount(props) {
  const apidata = props.apidata;
  const personaldata = apidata?.products['personal-accounts']?.data;
  
  return (
    <div>

    <div className="navbar-dark bg-dark" style={{   backgroundImage: `url("assets/svg/components/wave-pattern-light.svg")` }} >
          <div className="container content-space-1 content-space-b-lg-3">
            <div className="row align-items-center">
              <div className="col">
                <div className="d-none d-lg-block">
                  <h1 className="h2 text-white">Personal Accountancy</h1>
                </div>
    
            
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light mb-0">
                    <li className="breadcrumb-item">Your Personal Accountancy</li>
                   
                  </ol>
                </nav>
               
              </div>
           
    
              <div className="col-auto">
                <div className="d-none d-lg-block">
                  
                </div>
    
             
                <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <div className="navbar-toggler-default">
                    <i className="bi-list"></i>
                  </div>
                  <div className="navbar-toggler-toggled">
                    <i className="bi-x"></i>
                  </div>
                </button>
               
              </div>
            
            </div>
           
          </div>
        </div>
        <div className="container  content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className='row'>
    
    
            <div className="col-lg-3">
            <Sidebarmenu apidata={apidata}/>
            </div>   


        <div className="col-lg-9">
          <div className="d-grid gap-3 gap-lg-5">
            <div className="card">
              <div className="card-header border-bottom">
                <h4 className="card-header-title">Personal Accountancy</h4>
              </div>             
              <div className="card-body">

                <dl className="row">
                  <dt className="col-6">Trading Name:</dt>
                  <dd className="col-6">{personaldata?.trdngnme}</dd>

                  <dt className="col-6">National Insurance:</dt>
                  <dd className="col-6">{personaldata?.NI}</dd>

                  <dt className="col-6">UTR:</dt>
                  <dd className="col-6">{personaldata?.UTR}</dd>

                  <dt className="col-6">Started Self-Employment:</dt>
                  <dd className="col-6">{personaldata?.start_self}</dd>

                  <dt className="col-6">Filing Date:</dt>
                  <dd className="col-6">{personaldata?.FilingDte}</dd>
                </dl>





              </div>

            </div> 
          </div>

          <div className="d-grid gap-3 gap-lg-5 mt-4" >
            <div className="card">
              <div className="card-header border-bottom">
                <h4 className="card-header-title">Trading Information</h4>
              </div>             
              <div className="card-body">

                <dl className="row">
                  <dt className="col-6">Trading Name:</dt>
                  <dd className="col-6">{personaldata?.trdngnme}</dd>

                  <dt className="col-6">Trading Address:</dt>
                  <dd className="col-6">{personaldata?.TrdAddrss}</dd>

                  <dt className="col-6">Sage reference:</dt>
                  <dd className="col-6">{personaldata?.Sge_ref}</dd>
                </dl>





              </div>

            </div> 
          </div>


          <div className="d-grid gap-3 gap-lg-5 mt-4" >
            <div className="card">
              <div className="card-header border-bottom">
                <h4 className="card-header-title">Government Gateway</h4>
              </div>             
              <div className="card-body">

                <dl className="row">
                  <dt className="col-6">Login ID:</dt>
                  <dd className="col-6">{personaldata?.GG_ID}</dd>

                  <dt className="col-6">Password:</dt>
                  <dd className="col-6">{personaldata?.GG_Pass}</dd>

                  
                </dl>





              </div>

            </div> 
          </div>



        </div>         
      </div>

    </div>     

     
  </div>
  )
}
