import React from 'react'
import Sidebarmenu from '../components/Sidebarmenu'
export default function businessaccount(props) {
  const apidata = props.apidata;
  const businessaccount = apidata?.products['business-accounts']?.data;
  return (
    <div>

    <div className="navbar-dark bg-dark" style={{   backgroundImage: `url("assets/svg/components/wave-pattern-light.svg")` }} >
          <div className="container content-space-1 content-space-b-lg-3">
            <div className="row align-items-center">
              <div className="col">
                <div className="d-none d-lg-block">
                  <h1 className="h2 text-white">Business Accountancy</h1>
                </div>
    
            
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light mb-0">
                    <li className="breadcrumb-item">Business Accountancy Informations</li>
                   
                  </ol>
                </nav>
               
              </div>
           
    
              <div className="col-auto">
                <div className="d-none d-lg-block">
                 
                </div>
    
             
                <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <div className="navbar-toggler-default">
                    <i className="bi-list"></i>
                  </div>
                  <div className="navbar-toggler-toggled">
                    <i className="bi-x"></i>
                  </div>
                </button>
               
              </div>
            
            </div>
           
          </div>
        </div>
        <div className="container  content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className='row'>
    
    
            <div className="col-lg-3">
            <Sidebarmenu apidata={apidata}/>
            </div>   

        <div className="col-lg-9">
          <div className="d-grid gap-3 gap-lg-5">
            <div className="card">
              <div className="card-header border-bottom">
                <h4 className="card-header-title">Business Accountancy</h4>
              </div>             
              <div className="card-body">

                <dl className="row">
                  <dt className="col-6">Name:</dt>
                  <dd className="col-6">{businessaccount?.cnme}</dd>

                  <dt className="col-6">Company No:</dt>
                  <dd className="col-6">{businessaccount?.nmbr}</dd>

                  <dt className="col-6">Address:</dt>
                  <dd className="col-6">{businessaccount?.addrss}</dd>

                  <dt className="col-6">Authentication Code:</dt>
                  <dd className="col-6"></dd>
                  <dt className="col-6">UTR No:</dt>
                  <dd className="col-6">{businessaccount?.UTR}</dd>
                  <dt className="col-6">Directors :</dt>
                  <dd className="col-6">{businessaccount?.nmbr_dir}</dd>
                  <dt className="col-6">Employees :</dt>
                  <dd className="col-6">{businessaccount?.nmbr_empl}</dd>
                </dl>





              </div>

            </div> 
          </div>

          <div className="d-grid gap-3 gap-lg-5 mt-4" >
            <div className="card">
              <div className="card-header border-bottom">
                <h4 className="card-header-title">Trading Information</h4>
              </div>             
              <div className="card-body">

                <dl className="row">
                  <dt className="col-6">Date of Incorporation:</dt>
                  <dd className="col-6">{businessaccount?.Incrprtn}</dd>

                  <dt className="col-6">Confirmation statement Date:</dt>
                  <dd className="col-6">{businessaccount?.Cnfrmsttmntdte}</dd>

                  <dt className="col-6">Accounts reference number:</dt>
                  <dd className="col-6">{businessaccount?.account_ref}</dd>

                  <dt className="col-6">Accounts Year End date:</dt>
                  <dd className="col-6">{businessaccount?.AYrEddte}</dd>

                  <dt className="col-6">Submission Date:</dt>
                  <dd className="col-6">{businessaccount?.SubDate}</dd>

                  <dt className="col-6">PAYE reference number:</dt>
                  <dd className="col-6">{businessaccount?.PAYE_Ref}</dd>
                </dl>





              </div>

            </div> 
          </div>


          <div className="d-grid gap-3 gap-lg-5 mt-4" >
            <div className="card">
              <div className="card-header border-bottom">
                <h4 className="card-header-title">VAT/Sage Information
</h4>
              </div>             
              <div className="card-body">

                <dl className="row">
                  <dt className="col-6">VAT Number:</dt>
                  <dd className="col-6">{businessaccount?.vat_id}</dd>

                  <dt className="col-6">Period of Return:</dt>
                  <dd className="col-6">{businessaccount?.VAT_return}</dd>

                  <dt className="col-6">Sage Reference:</dt>
                  <dd className="col-6">{businessaccount?.Sge_ref}</dd>
                </dl>





              </div>

            </div> 
          </div>


          <div className="d-grid gap-3 gap-lg-5 mt-4" >
            <div className="card">
              <div className="card-header border-bottom">
                <h4 className="card-header-title">Government Gateway</h4>
              </div>             
              <div className="card-body">

                <dl className="row">
                  <dt className="col-6">Login ID:</dt>
                  <dd className="col-6">{businessaccount?.GGID}</dd>

                  <dt className="col-6">Password:</dt>
                  <dd className="col-6">{businessaccount?.GGPass}</dd>

                  
                </dl>





              </div>

            </div> 
          </div>


        </div>         
      </div>

    </div>     

     
  </div>
  )
}
