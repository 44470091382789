
import React,{useEffect, useState} from 'react'
import './App.css';
import Header from './components/Header';
import Sidebarmenu from './components/Sidebarmenu';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';

import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation  } from 'react-router-dom';
import axios from 'axios';
import Profile from './pages/Profile';
import Creditorinfo from './pages/Creditorinfo';
import Invoices from './pages/Invoices';
import Personalaccount from './pages/Personalaccount';
import Businessaccount from './pages/Businessaccount';
import Budgetinglist from './pages/Budgetinglist';
import Incomeandexpense from './pages/Incomeandexpense';
import Comparebanks from './pages/Comparebanks';
import Uploaddocs from './pages/Uploaddocs';
import SMSlist from './pages/SMSlist';
import Emaillist from './pages/Emaillist';
import Letterslist from './pages/Letterslist';
import AuthRoutes from './routes/Authroutes';
import NewsLetterslist from './pages/NewsLetterslist';
import Changepassword from './pages/Changepassword';

function App() {
//  axios.defaults.baseURL = 'https://client.clearstartaccountants.co.uk/app/';






 
 



    if(localStorage.getItem('login')==='undefined')
    {
      console.log('logout');  
      localStorage.clear();      
      
    }

  
   
    const apidata = JSON.parse(localStorage.getItem('data'));



 
  return (
    <Router>
        <div className="App">
        <Header apidata={apidata}></Header>
          <Routes>
        
              <Route  path='/' element={<AuthRoutes />}>  
                <Route  exact path='/' element={<Dashboard apidata={apidata} />} />
                <Route  exact path='/dashboard' element={<Dashboard apidata={apidata} />} />
                <Route  exact path='/profile' element={<Profile apidata={apidata} />}  />
                <Route  exact path='/creditors-info' element={<Creditorinfo apidata={apidata} />} />
                <Route  exact path='/comparebank' element={<Comparebanks apidata={apidata} />} />
                <Route  exact path='/personal-account' element={<Personalaccount apidata={apidata} />} />
                <Route  exact path='/business-account' element={<Businessaccount apidata={apidata} />} />
                <Route  exact path='/budgetlist' element={<Budgetinglist apidata={apidata} />} />
                <Route  exact path='/income-expense' element={<Incomeandexpense apidata={apidata} />} />
                <Route  exact path='/invoices' element={<Invoices apidata={apidata} />} />      
                <Route  exact path='/letters' element={<Letterslist apidata={apidata} />} />  
                <Route  exact path='/emails' element={<Emaillist apidata={apidata} />} />  
                <Route  exact path='/smsdetails' element={<SMSlist apidata={apidata} />} />  
                <Route  exact path='/uploaddocs' element={<Uploaddocs apidata={apidata} />} />  
                <Route  exact path='/newsletters' element={<NewsLetterslist apidata={apidata} />} />  
                <Route  exact path='/changepassword' element={<Changepassword apidata={apidata} />} />  
              </Route>      
              <Route  exact path='/login' element={<Login />} />  
            
              
        </Routes>       
        </div>
    </Router>
  );
}

export default App;
