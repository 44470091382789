import React, {useState, useEffect} from 'react'
import axios from 'axios';
import Sidebarmenu from '../components/Sidebarmenu'
export default function Uploaddocs(props) {
  const apidata = props.apidata;
  const userid = apidata.Client.id;


  const [loading, setloading]= useState(false);
  const [success, setsuccess]= useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const uploadform = (event) => {
  
   console.log('function loading');  
   setloading(true);

    event.preventDefault()

      const formData = new FormData();
      formData.append("file", selectedFile);
 
    axios({
            method: "post",
            url:  "https://client.clearstartaccountants.co.uk/app/api/file-"+userid+"/" ,
            data: formData,
          
          }).then((response) =>{ 
            console.log(formData);
            console.log("upload successfull",response);
             setloading(false);
            setsuccess(true);
            }).catch((error) => {  
              setloading(false);
              console.log("upload error",error);
         //  console.log(error)
          });
        
        
      
       
      }
  


  return (
    <div>

    <div className="navbar-dark bg-dark" style={{   backgroundImage: `url("assets/svg/components/wave-pattern-light.svg")` }} >
          <div className="container content-space-1 content-space-b-lg-3">
            <div className="row align-items-center">
              <div className="col">
                <div className="d-none d-lg-block">
                  <h1 className="h2 text-white">Upload Documents</h1>
                </div>
    
            
            
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light mb-0">
                    <li className="breadcrumb-item">Upload any letters or documents </li>
                   
                  </ol>
                </nav>
               
              </div>
           
    
              <div className="col-auto">
                <div className="d-none d-lg-block">
                 
                </div>
    
             
                <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <div className="navbar-toggler-default">
                    <i className="bi-list"></i>
                  </div>
                  <div className="navbar-toggler-toggled">
                    <i className="bi-x"></i>
                  </div>
                </button>
               
              </div>
            
            </div>
           
          </div>
        </div>
        <div className="container  content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className='row'>
    
    
            <div className="col-lg-3">
            <Sidebarmenu apidata={apidata}/>
            </div>  


            <div className="col-lg-9">
          <div className="d-grid gap-3 gap-lg-5">
          <div className="card">
              <div className="card-header border-bottom">
                <h4 className="card-header-title">Upload Documents
</h4>
              </div>             
              <div className="card-body">

              {success? <>
                <div className="alert alert-success" role="alert">
                  Upload successfull. 
                </div>
                </>: <>
                
                </>}
            
              <form encType='multipart/formData'>

              <div for="dropzone" className=" row dz-dropzone dz-dropzone-card">
                <div className="dz-message">
                  <span className="svg-icon svg-icon-lg mb-3">


                  </span>

                 

                  <p className="mb-5"> Upload any letters or documents directly into your portal. A member of Customer Care Team will action them <b>within 2 working days.</b></p>

                  <input type="file" onChange={(e)=> setSelectedFile(e.target.files[0])} className="form-control"  />
                  <button type='button' onClick={uploadform} className='btn btn-success btn-sm mt-5'disabled={loading}>{loading?'Please Wait':'Upload Document'}  </button>
                </div>
              </div>
       
              </form>




              </div>

            </div> 
        
          </div>
        </div> 
      </div>

    </div>     

     
  </div>
  )
}
