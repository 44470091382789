import React, {useState} from 'react'
import axios from 'axios'
import { useNavigate, Link  } from 'react-router-dom';

export default function Header(props) {
  const apidata = props?.apidata;

  const navigate = useNavigate();
  const logout = () =>{     
    console.log('logout');  
      localStorage.clear();      
      navigate('/login')  
  }


  return (
    <div style={{marginBottom:'-15px',}}>

<header className="navbar navbar-expand-lg navbar-shadow navbar-end mb-3">
  <div className="container">
    <div className="navbar-nav-wrap">
      <div className="navbar-brand-wrapper">

        <Link to="/dashboard" className="navbar-brand" aria-label="Front">
          <img className="navbar-brand-logo" src="../assets/svg/logos/logo.png" alt="Logo"/>
        </Link>

      </div>


      <button type="button" className="navbar-toggler ms-auto" data-bs-toggle="collapse" data-bs-target="#navbarNavMenuShadow" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navbarNavMenuShadow">
        <span className="navbar-toggler-default">
          <i className="bi-list"></i>
        </span>
        <span className="navbar-toggler-toggled">
          <i className="bi-x"></i>
        </span>
      </button>


      <nav className="navbar-nav-wrap-col collapse navbar-collapse" id="navbarNavMenuShadow">

        <ul className="navbar-nav">
         

         

          <li className="nav-item">
              <a className="btn btn-success btn-transition" style={{backgroundColor:'#08605F'}} href='https://clearstartaccountants.co.uk/'  > Home</a>
           </li>
           {apidata?
           <li className="nav-item">
              <a className="btn btn-danger btn-transition" href='#' onClick={logout}> <i className="bi-box-arrow-right nav-icon"></i> Logout</a>
           </li>
          :<></>}
        </ul>

      </nav>
    </div>
  </div>
</header>

    </div>
  )
}
