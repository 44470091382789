import React from 'react'
import Sidebarmenu from '../components/Sidebarmenu'
export default function Emaillist(props) {
  const apidata = props.apidata;
  const emaillist = apidata.Correspondence.email.list;


  return (
    <div>

    <div className="navbar-dark bg-dark" style={{   backgroundImage: `url("assets/svg/components/wave-pattern-light.svg")` }} >
          <div className="container content-space-1 content-space-b-lg-3">
            <div className="row align-items-center">
              <div className="col">
                <div className="d-none d-lg-block">
                  <h1 className="h2 text-white">Emails</h1>
                </div>
    
            
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light mb-0">
                    <li className="breadcrumb-item"> Your Email History</li>
                   
                  </ol>
                </nav>
               
              </div>
           
    
              <div className="col-auto">
                <div className="d-none d-lg-block">
                 
                </div>
    
             
                <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <div className="navbar-toggler-default">
                    <i className="bi-list"></i>
                  </div>
                  <div className="navbar-toggler-toggled">
                    <i className="bi-x"></i>
                  </div>
                </button>
               
              </div>
            
            </div>
           
          </div>
        </div>
        <div className="container  content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className='row'>
    
    
            <div className="col-lg-3">
            <Sidebarmenu apidata={apidata}/>
            </div> 

        <div className="col-lg-9">
          <div className="d-grid gap-3 gap-lg-5">
          <div className="card">
              <div className="card-header border-bottom">
                <h4 className="card-header-title">Email History</h4>
              </div>             
             


                    
              <div className="table-responsive">
                <table className="table table-borderless table-thead-bordered table-nowrap table-align-middle">
                  <thead className="thead-light">
                    <tr>
                      <th>From</th>
                      <th>Status</th>
                      
                      <th>Time</th>                    
                    
                    </tr>
                  </thead>

                  <tbody>
                          
              {emaillist.map(data => {                        
                      return (          
                    <tr key={data.id} >
                      <td><a href="#">{data.email_from_text}</a> <br></br>
                      <small>{data.email_subject}</small></td>
                      <td><span className="badge bg-soft-success text-success">{data.email_drctn}</span></td>
                     
                      <td><small>{data.email_datetime}</small></td>                    
                  
                    </tr>

)
})} 

                  
                  </tbody>
                </table>
              </div>
              




              </div>

            
          </div>
        </div> 
      </div>

    </div>     

     
  </div>
  )
}
